<template>
  <div class="userpage">
    <v-toolbar flat dense>
      <v-toolbar-title>My Subscriptions</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn x-small text color="primary" @click="refresh">
        refresh data
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card flat>
      <v-card-text>
        <v-data-iterator :items="subscribeList">
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="12"
                sm="6"
                md="6"
                lg="6"
              >
                <v-card flat outlined>
                  <v-card-text class="card_list">
                    <ul>
                      <li>
                        <div class="myleft">offer type</div>
                        <div class="myright">
                          {{ item.subscriber_typesName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li v-show="isMedics">
                        <div class="myleft">specialty</div>
                        <div class="myright">
                          {{ item.specializationName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li v-show="isMedics">
                        <div class="myleft">specialty detail</div>
                        <div class="myright">
                          {{ item.specializationDetailName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">active</div>
                        <div class="myright">
                          {{ item.user.active ? "Yes" : "No" }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                    </ul>
                  </v-card-text>
                  <v-card-actions v-show="isMedics" style="padding: 8px 16px">
                    <v-btn
                      text
                      x-small
                      color="primary"
                      @click.stop="$set(updateDialog, item.id, true)"
                    >
                      update
                    </v-btn>
                    <MySubUpdate
                      v-if="updateDialog[item.id]"
                      :updateDialog="updateDialog[item.id]"
                      :name="item.subscriber_typesName"
                      :myId="item.id"
                      @closeUpdate="closeUpdate"
                      @submitUpdate="submitUpdate"
                    />
                    <v-spacer></v-spacer>
                    <v-btn
                      x-small
                      text
                      color="indigo"
                      :disabled="subTypeDisable(item.subscriber_typesName)"
                    >
                      go to {{ item.subscriber_typesName }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../mixins/utils.js";
export default {
  components: {},
  mixins: [Utils],
  data: () => ({
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    updateDialog: {},
    loading: false,
    subscribeList: [],
  }),
  computed: {
    isMedics: function () {
      let subtype = localStorage.getItem("user.subscriber_type");
      if (subtype > 1) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    refresh() {
      this.list();
    },
    subTypeDisable(n) {
      if (n === localStorage.getItem("user.subscriber_name")) {
        return true;
      } else {
        return false;
      }
    },
    list() {
      let self = this;
      Restful.accounts.mySubs
        .list()
        .then((response) => {
          console.log(response.data);
          this.subscribeList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeUpdate(p) {
      this.$set(this.updateDialog, p.id, p.state);
    },
    submitUpdate(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.accounts.mySubs
        .update(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.updateDialog, p.id, p.state);
    },
  },
};
</script>
